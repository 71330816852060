import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  itemInTransition = false;
  projects = [
    {
      name: 'Lights Out', 
      description: 'The classic lights out game made with React.', 
      technologies: 'React, Redux, HTML, CSS',
      features: ['Select light color', 'Level seletion', 'Restart level', 'Infinite undo', 'Show solution'],
      snapshot: 'lightsout.png',
      height: null,
      width: null,
      source: 'https://github.com/jmdagenais/lights-off',
      demo: 'http://lightsout.jeanmicheldn.com'
    },
    {
      name: 'Movie Search', 
      description: 'Query the Open Movie Database to search for movies and display details about selected movie. (This is one of my first Angular app)', 
      technologies: 'Angular, HTML, CSS, Bootstrap, call REST API',
      snapshot: 'omdb.png',
      height: '100%',
      width: '100%',
      source: 'https://github.com/jmdagenais/OMDB-Search',
      demo: 'http://omdb.jeanmicheldn.com'
    },
    {
      name: 'Recipe book', 
      description: 'It\'s an application to manage recipes. I did the front end and the backend for this project.', 
      technologies: 'MEAN Stack (MongoDB, Express.js, Angular, Node), PrimeNg, HTML, CSS',
      features: ['Create, edit, delete recipes', 'View recipe details', 'Filter recipes by categories'],
      note: 'To create, edit and delete recipes go to /admin. The password is "demo2".',
      snapshot: 'recipes.png',
      height: '100%',
      width: '100%',
      source: 'https://github.com/jmdagenais/find-recipes',
      demo: 'http://recipes-demo.jeanmicheldn.com'
    },
  ]
  index = 0;
  project = this.projects[this.index];;
  
  // projectCount = 3;
  // displayIndex = 0;

  

  constructor() { }

  ngOnInit() {
    this.project = this.projects[this.index];
  }

  previous() {
    if (this.index == 0) {
      this.index = this.projects.length - 1;
    } else {
      this.index--;
    }
    this.changeItem()
  }

  next() {
    if (this.index == this.projects.length - 1) {
      this.index = 0;
    } else {
      this.index++;
    }
    this.changeItem()
  }

  changeItem() {
    this.itemInTransition = true;
    
    setTimeout(() => {
      this.project = this.projects[this.index];
    }, 150)

    setTimeout(() => {
      this.itemInTransition = false;
    }, 300)
  }

}
